<template>
    <router-view/>
</template>

<script>
// import {codeToSession} from '@/service/session.js';
import request from "@/utils/request.js";

export default {
    name: 'CommonLayerLayout',
    components: {},
    data() {
        return {};
    },
    created() {
        const appCode = process.env.VUE_APP_CODE;
        // 这里目前用来处理一些公共的前端逻辑，
        // 目前就是，从Cookies中读取AppCode，扩展到request对象中
        request.extendOptions({
            headers: {
                "App-Code": appCode
            }
        });
        console.log('CommonLayerLayout#setCookie');
    },
}
</script>

<style lang="less">

</style>